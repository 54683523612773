import { Button, Col, Empty, Spin, Row, message } from "antd";

import { useEffect, useState } from "react";
import Logo from "../assets/logo.png";
import Man from "../assets/man.png";
const Home = () => {
  return (
    <>
      {/* <div style={{ height: "100vh" }}>
      <img src={BackGround} width="100%"></img>
    </div> */}
      <div class="bg">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 col-sm-8" style={{ paddingLeft: "30px" }}>
              <div>
                <img src={Logo} width="100px" height="100px"></img>
              </div>
              <p
                className="onetext"
                style={{
                  color: "white",
                  fontFamily: "zendot",
                  marginTop: "35px",

                  marginBottom: "0",
                }}
              >
                THE BANDIT PROJECT
              </p>
              <p
                className="twotext"
                style={{
                  color: "white",
                  fontFamily: "zendot",

                  marginBottom: "0",
                }}
              >
                DEFI CITY
              </p>
              <p
                style={{
                  color: "white",
                  fontFamily: "poppins",

                  marginTop: "20px",
                }}
                className="text3"
              >
                First-Ever Daily ROI NFTs on BSC
              </p>
              <div>
                <Button
                  className="buttonhome"
                  style={{
                    color: "#ed2e8a",
                    // width: "20vw",
                    height: "40px",
                  }}
                  type="primary"
                  shape="round"
                  onClick={() => message.info("Coming Soon!")}
                >
                  <b> Play Now</b>
                </Button>
                <a href="https://we.tl/t-DQt2wCuvKc">
                  <Button
                    className="marginLeftCLASS buttonhome"
                    style={{
                      color: "#ed2e8a",
                      // width: "20vw",
                      height: "40px",
                    }}
                    type="primary"
                    shape="round"
                  >
                    <b>Download (PC)</b>
                  </Button>
                </a>
              </div>

              <p
                className="bottomtext"
                style={{
                  color: "white",
                  fontFamily: "zendot",

                  position: "absolute",
                  left: 30,
                  bottom: 0,
                }}
              >
                The Bandit Project 2022. All rights reserved
              </p>
            </div>
            <div className="col-md-4  col-sm-4">
              <div>
                <img className="man" src={Man}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
