import React, { Fragment } from "react";

const Error404 = () => {
  return (
    <Fragment>
      <div
        style={{
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1>Page Not Found</h1>
      </div>
    </Fragment>
  );
};
export default Error404;
